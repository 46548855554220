import React from 'react';

import Layout from '../components/layout';
import Gallery from '../components/gallery';

import './main.scss';
import SEO from '../components/seo';

const IndexPage = ({ location } : any) => (
  <Layout>
    <SEO title="Gallery - The Colour of the Climate Crisis" />
    <Gallery location={location} />
  </Layout>
);

export default IndexPage;
