import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import Loadable from '@loadable/component';
import { getImagesFromData } from '../../utils';

const LoadableGalleryRenderer = Loadable(() => import('../gallery-renderer'));

const Renderer = ({ location }: { location: { state: any } }) => {
  const data = useStaticQuery(graphql`
    query {
      django {
        allArtworks {
          slug
          imageFile {
            childImageSharp {
              gatsbyImageData(
                width: 450
                quality: 95
              )
            }
          }
          image
        }
      }
    }
  `);

  const images = getImagesFromData(data);

  return <LoadableGalleryRenderer images={images} location={location} />;
};

export default Renderer;
